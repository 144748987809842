import React from "react";
import styled from "styled-components";

{/* <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_206_2)">
<path d="M11.5 0.479145C5.14625 0.479145 0 5.53914 0 11.7798C0 16.7737 3.29475 21.0086 7.86312 22.5016C8.43812 22.608 8.64896 22.2582 8.64896 21.9583C8.64896 21.6899 8.63937 20.9789 8.63458 20.0368C5.43567 20.7182 4.761 18.5207 4.761 18.5207C4.23775 17.2164 3.48162 16.8676 3.48162 16.8676C2.43992 16.1671 3.56212 16.1814 3.56212 16.1814C4.71692 16.26 5.32354 17.3458 5.32354 17.3458C6.34896 19.0737 8.0155 18.5744 8.67292 18.2859C8.77642 17.5547 9.07254 17.0574 9.40125 16.7746C6.84729 16.4919 4.163 15.5202 4.163 11.1904C4.163 9.95706 4.60862 8.94889 5.34654 8.15827C5.21717 7.87269 4.82904 6.72364 5.44717 5.16731C5.44717 5.16731 6.41029 4.86448 8.60967 6.32594C9.52967 6.07485 10.5072 5.95027 11.4847 5.94452C12.4622 5.95027 13.4397 6.07485 14.3597 6.32594C16.5447 4.86448 17.5078 5.16731 17.5078 5.16731C18.1259 6.72364 17.7378 7.87269 17.6228 8.15827C18.3559 8.94889 18.8015 9.95706 18.8015 11.1904C18.8015 15.5317 16.1134 16.4871 13.5547 16.7651C13.9572 17.1043 14.3309 17.7972 14.3309 18.8561C14.3309 20.3684 14.3165 21.5836 14.3165 21.9506C14.3165 22.2467 14.5178 22.6004 15.1072 22.4873C19.7081 21.0038 23 16.766 23 11.7798C23 5.53914 17.8509 0.479145 11.5 0.479145V0.479145Z" fill="#E4E4E4"/>
</g>
<defs>
<clipPath id="clip0_206_2">
<rect width="23" height="23" fill="white"/>
</clipPath>
</defs>
</svg> */}

const Github = (props) => (
  <SvgCSS
    width={23}
    height={23}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_206_2)">
      <path
        d="M11.5 0.479145C5.14625 0.479145 0 5.53914 0 11.7798C0 16.7737 3.29475 21.0086 7.86312 22.5016C8.43812 22.608 8.64896 22.2582 8.64896 21.9583C8.64896 21.6899 8.63937 20.9789 8.63458 20.0368C5.43567 20.7182 4.761 18.5207 4.761 18.5207C4.23775 17.2164 3.48162 16.8676 3.48162 16.8676C2.43992 16.1671 3.56212 16.1814 3.56212 16.1814C4.71692 16.26 5.32354 17.3458 5.32354 17.3458C6.34896 19.0737 8.0155 18.5744 8.67292 18.2859C8.77642 17.5547 9.07254 17.0574 9.40125 16.7746C6.84729 16.4919 4.163 15.5202 4.163 11.1904C4.163 9.95706 4.60862 8.94889 5.34654 8.15827C5.21717 7.87269 4.82904 6.72364 5.44717 5.16731C5.44717 5.16731 6.41029 4.86448 8.60967 6.32594C9.52967 6.07485 10.5072 5.95027 11.4847 5.94452C12.4622 5.95027 13.4397 6.07485 14.3597 6.32594C16.5447 4.86448 17.5078 5.16731 17.5078 5.16731C18.1259 6.72364 17.7378 7.87269 17.6228 8.15827C18.3559 8.94889 18.8015 9.95706 18.8015 11.1904C18.8015 15.5317 16.1134 16.4871 13.5547 16.7651C13.9572 17.1043 14.3309 17.7972 14.3309 18.8561C14.3309 20.3684 14.3165 21.5836 14.3165 21.9506C14.3165 22.2467 14.5178 22.6004 15.1072 22.4873C19.7081 21.0038 23 16.766 23 11.7798C23 5.53914 17.8509 0.479145 11.5 0.479145V0.479145Z"
        fill="#E4E4E4"
      />
    </g>
    <defs>
      <clipPath id="clip0_206_2">
        <rect width={23} height={23} fill="#E4E4E4" />
      </clipPath>
    </defs>
  </SvgCSS>
);

const SvgCSS = styled.svg`
path{
transition: 500ms ease-in-out;
}
&:hover {
  path {
    fill: #6e5494;
  }
}
`
export default Github;